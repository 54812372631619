import styled from "styled-components";

export const Screen = styled.div`
  font-family: "Lora", serif;
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const StyledButton = styled.button`
  font-family: "Lora", serif;
  margin: 6px 0px 2px;
  border: solid 1px rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 12px;
  color: #333;
  width: 180px;
  cursor: pointer;
  box-shadow: 0px 2px 0px 0px rgba(20, 20, 20, 0.5);
  -webkit-box-shadow: 0px 2px 0px 0px rgba(20, 20, 20, 0.5);
  -moz-box-shadow: 0px 2px 0px 0px rgba(20, 20, 20, 0.5);
  transition: .1s;
  :active {
    transform: translate3d(0, 2px, 0);
    box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 10px;
  font-size: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 2px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 2px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const TextTitle = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

export const TextDescription = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
`;

export const SpacerXSmall = styled.div`
  height: 12px;
  width: 12px;
`;
